/*
/ Ronan.Design
*/


// dependencies

@import './node_modules/normalize.css/normalize';
@import './node_modules/bulma/bulma';
@import './switch.scss';


// colors

$front: #C15247;
$back: #F2C495;

$personal: #50E3C2;
$job: #F8E71C;
$oss: #D3DDF7;

@mixin transition($property:all, $duration:0.3s, $easing:ease-in-out) {
  transition: $property $duration $easing;
}


// typography

@mixin workSans {
  font-family: 'Work Sans', sans-serif;
  letter-spacing: -0.025em;
}

body {
  @include workSans;
  font-size: 21px;
  line-height: 1.725;
}

p {
  margin: 1em 0;
}


// layout

html,
body {
  min-height: 100%;
  height: 100%;
  background: #f4f4eb;
  overflow-x: hidden;
}

#main {
  width: 100%;
}

.wrap {
  min-height: 100%;
  margin-bottom: 0;
  overflow: hidden;
}

input[type=checkbox] {
  position: absolute;
  left: -9999em;
}

article {
  margin-left: auto;
  margin-right: auto;
  max-width: 62.5vw;
  
  h1 {
    margin: 0 0 5em;
    
    svg {
      max-height: 2vw;
    }
  }

  p {
    font-size: 1.667vw;
    padding-right: 2vw;
  }

  a {
    position: relative;
    display: inline-block;
    color: darken(#485fc7, 11.25%);
    @include transition;

    &:hover {
      color: $front;
    }

    &.hilite:after {
      position: absolute;
      display: block;
      border-radius: 50%;
      content: " ";
      width: 2em;
      height: 2em;
      left: 50%;
      margin: -1.75em 0 0 -1em;
      opacity: 0.08;
      @include transition;
    }

    &.hilite:hover:after {
      opacity: 0.5;
    }

    &.personal:after {
      background-color: $personal;
    }

    &.job:after {
      background-color: $job;
    }

    &.oss:after {
      background-color: $oss;
    }
  }
}

#sidebar {
  height: 100vh;
  padding-top: 1rem;
  padding-bottom: 2rem;
  overflow-y: scroll;

  .panel {
    box-shadow: none;
    height: 100vh;
    overflow-y: scroll;
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 28.5%;
    background: white;
  }

  dl {
    padding: 4rem 2.5vw 3rem;
    margin-left: 1vw;
  }

  dt {
    font-size: 1.67rem;
  }

  dd {
    margin-bottom: 4rem;
    font-size: 1.125rem;
    position: relative;
    opacity: 0.5;
    padding: 0.5rem 1rem 0 0;
    line-height: 1.425;

    em {
      position: absolute;
      top: -4.25rem;
      font-style: normal;
      font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    &:hover {
      opacity: 1;

    }
  }
}

footer {
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  right: 31.5vw;

  a {
    padding: 2vw 1vw;
    opacity: 0.8;
    @include transition;
  }

  &:hover a {
    opacity: 1;
  }

  #wcb {
    float: right;
  }
}


// responsive

@media screen and (max-width: 1023px) {
  body {
    overflow-y: scroll;
  }

  .columns.is-mobile {
    display: block;
  }

  article {
    margin-left: auto;
    margin-right: auto;
    max-width: 86vw;
    padding-bottom: 5rem;

    h1 {
      margin: 3.333rem 0.5rem;

      img, svg {
        min-height: 1.75rem;
      }
    }

    p {
      font-size: 1.2rem;
    }
  }

  #sidebar {
    min-height: auto;
    height: auto;
    padding: 0;
    display: none;
    
    .panel {
      position: relative;
      top: auto;
      right: auto;
      max-width: 100%;
      height: auto;
      overflow: visible;
      background: transparent;
    }

    dl {
      margin: 0 auto;
      padding: 4rem 7vw 3rem;
    }

    dt {
      font-size: 1.67rem;
    }
    dd {
      font-size: 1rem;
      opacity: 1;
      line-height: 1.636;

      em {
        top: -4rem;
        opacity: 0.67;
        font-size: 0.825rem;
      }
    }
  }

  section.mobile-switch {
    position: fixed;
    background: white;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    height: 4.33rem;
    text-align: center;
    line-height: 1;
    border-top: 1px solid rgba(0,0,0,0.1);

    label {
      margin: 1.3rem auto 0;
    }

    span {
      position: absolute;
      display: inline-block;
      font-size: 1rem;
      top: 1.625rem;

      &:first-of-type {
          left: 50%;
          margin-left: -8.5rem;
          text-align: right;
      }
      &:last-of-type {
        left: auto;
        right: 50%;
        margin-left: 0;
        margin-right: -8rem;
        text-align: left;
      }
    }
  }

  input[type=checkbox]:checked + #main {

    #article {
      display: none !important;
    }
    #sidebar {
      display: flex !important;
    }
  }
}

@media screen and (max-width: 1023px) {
  #sidebar {
    .panel {
      position: relative;
      top: auto;
      right: auto;
      max-width: 100%;
    }
  }
}
